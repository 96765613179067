import React, { useState, useEffect } from "react";
import Logo from "./logo";
import { Link, useLocation } from 'react-router-dom';
import ThemeSwitcher from "./ThemeSwitcher";
import { FaBars, FaTimes } from 'react-icons/fa';

const BasicNav = ({ showButton }) => {
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    return isDarkMode;
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', String(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white shadow-md fixed z-50 w-full border-gray-200 px-2 py-1 dark:bg-gray-800">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="flex items-center">
          <Logo width="60px" height="50px" darkMode={darkMode} />
          <span 
             onClick={() => window.location.href = 'https://app.servicegest.ro'}
            className="self-center text-xl font-semibold whitespace-nowrap dark:text-white cursor-pointer">
            Servicegest
          </span>
        </div>
        
        <div className="flex items-center lg:ml-auto">
          <ThemeSwitcher darkMode={darkMode} toggleDarkMode={toggleDarkMode} />

          {location.pathname === '/' && (

          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
          )}
        </div>
        
        <div className={`${isMenuOpen ? 'block' : 'hidden'} w-full lg:flex lg:w-auto lg:order-1`}>
          <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
            {location.pathname === '/' && (
              <li>
                <Link 
                  to="/login"
                  className="block p-2 bg-purple-600 rounded lg:bg-transparent text-gray-900 dark:text-white"
                >
                  Login Service
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default BasicNav;