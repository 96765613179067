import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import { auth, onAuthStateChanged } from './firebase'
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from './firebase';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LoginPage from './pages/loginPage';
import DashboardPage from './pages/dashboardPage';
import PricingPage from './pages/pricingPage';
import Loading from './components/loading';
import EndUser from './pages/endUser';
import EmployeePage from './pages/employeePage';
import Review from './pages/review';
import NotificationPage from './pages/NotificationPage';
import './App.css';

const NotificationPageWithTitle = () => {
  return (
    <>
      <Helmet>
        <title>Notificări ITP | Servicegest</title>
      </Helmet>
      <NotificationPage />
    </>
  );
};

function App() {
  const [loading, setLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);
  const [userRole, setUserRole] = useState(null);
  
  useEffect(() => {
    const authInstance = auth;
    const db = firestore;  
  
    onAuthStateChanged(authInstance, async (user) => {
      if (user) {
        const q = query(collection(db, "customers", user.uid, "subscriptions"), where("role", "==", "premium"));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          setUserRole('premium');
        } else {
          setUserRole('standard');
        }
      } else {
        setUserRole(null);
      }
  
      setLoading(false);
      setAuthChecked(true);
    });
  }, []);

  if (loading || !authChecked) {
    return <Loading />;
  }

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard/*" element={userRole === 'premium' ? <DashboardPage /> : <Navigate to="/pricing" />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/" element={<EndUser/>} />
          <Route path="/notification" element={<NotificationPageWithTitle /> }/>
          <Route path="/employee/*" element={<EmployeePage /> }/>
          <Route path="/review" element={<Review/>}/>
          <Route path="/loading" element={<Loading/>}/>
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;