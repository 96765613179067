"use client"

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const DashboardSupport = () => {
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [issueType, setIssueType] = useState("");
    const [category, setCategory] = useState("");
    const [priority, setPriority] = useState("medium");
    const [attachments, setAttachments] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        console.log({ subject, description, issueType, category, priority, attachments });
        setIsSubmitted(true);
        setTimeout(() => setIsSubmitted(false), 3000);
    };

    const handleFileChange = (event) => {
        setAttachments([...event.target.files]);
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.1
          }
        }
      };
    
      const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            stiffness: 100
          }
        }
      };

    return (
        <motion.div
            id="main-content"
            className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <main className="p-4">
                <motion.form
                    onSubmit={handleSubmit}
                    className="p-6 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700"
                    variants={containerVariants}
                >
                    <motion.div className="mb-4" variants={itemVariants}>
                        <label
                            htmlFor="subject"
                            className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                        >
                            Subiect
                        </label>
                        <input
                            type="text"
                            id="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            required
                        />
                    </motion.div>
                    <motion.div className="mb-4" variants={itemVariants}>
                        <label
                            htmlFor="issueType"
                            className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                        >
                            Tip Problemă
                        </label>
                        <select
                            id="issueType"
                            value={issueType}
                            onChange={(e) => setIssueType(e.target.value)}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            required
                        >
                            <option value="">Selectează tipul problemei</option>
                            <option value="bug">Bug</option>
                            <option value="feature-request">Cerere de funcționalitate</option>
                            <option value="account">Probleme cont</option>
                            <option value="billing">Facturare</option>
                            <option value="other">Altele</option>
                        </select>
                    </motion.div>
                    <motion.div className="mb-4" variants={itemVariants}>
                        <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                        >
                            Categorie
                        </label>
                        <select
                            id="category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            required
                        >
                            <option value="">Selectează categoria</option>
                            <option value="technical">Tehnic</option>
                            <option value="financial">Financiar</option>
                            <option value="general">General</option>
                            <option value="feedback">Feedback</option>
                        </select>
                    </motion.div>
                    <motion.div className="mb-4" variants={itemVariants}>
                        <label
                            htmlFor="priority"
                            className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                        >
                            Prioritate
                        </label>
                        <select
                            id="priority"
                            value={priority}
                            onChange={(e) => setPriority(e.target.value)}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        >
                            <option value="low">Scăzută</option>
                            <option value="medium">Medie</option>
                            <option value="high">Ridicată</option>
                            <option value="urgent">Urgentă</option>
                        </select>
                    </motion.div>
                    <motion.div className="mb-4" variants={itemVariants}>
                        <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                        >
                            Descriere
                        </label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            rows="4"
                            required
                        />
                    </motion.div>
                    <motion.div className="mb-4" variants={itemVariants}>
                        <label
                            htmlFor="attachments"
                            className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                        >
                            Atașamente
                        </label>
                        <input
                            type="file"
                            id="attachments"
                            onChange={handleFileChange}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            multiple
                        />
                    </motion.div>
                    <motion.button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        Trimite Ticket
                    </motion.button>
                    <AnimatePresence>
                        {isSubmitted && (
                            <motion.p
                                className="text-green-600 mt-2"
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                            >
                                Ticketul a fost trimis cu succes!
                            </motion.p>
                        )}
                    </AnimatePresence>
                </motion.form>
            </main>
        </motion.div>
    );
};

export default DashboardSupport;